import { Component } from '@angular/core';
import { Ecommerce_Config } from './Ecommerce_Config';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  query: string = '';
  constructor() { }

  currentPage() {
    return Ecommerce_Config['current_page'];
  }

  getCartCount() {
    let cart = JSON.parse(window.localStorage.getItem('cart'));
    if (cart) {
      return cart.length;
    } else {
      return 0;
    }
  }
}
