import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Ecommerce_Config } from '../Ecommerce_Config';

@Component({
  selector: 'app-catpopover',
  templateUrl: './catpopover.page.html',
  styleUrls: ['./catpopover.page.scss'],
})
export class CatpopoverPage implements OnInit {
  @Input() category;
  constructor(private route: Router, public popover: PopoverController) { }
  
  goToProducts(cid) {
    this.route.navigate(['/product/' + cid]);
    this.popover.dismiss();
  }

  ngOnInit() {
  }

}
