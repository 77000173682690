import { Component, OnInit } from '@angular/core';
import { RestApiService } from '../rest-api.service';
import { CommonFunctionsService } from '../common-functions.service';
import { ModalController } from '@ionic/angular';
import { Ecommerce_Config } from '../Ecommerce_Config';

@Component({
  selector: 'app-homelocation',
  templateUrl: './homelocation.page.html',
  styleUrls: ['./homelocation.page.scss'],
})
export class HomelocationPage implements OnInit {
  isLoading: boolean = true;
  data: any = [];
  qry: string = '';
  constructor(public modalController: ModalController, public restApi: RestApiService, public commonFunctions: CommonFunctionsService) { }

  ngOnInit() {
    this.restApi.get('customer_active_location').subscribe({
      next: (data: any) => {
        this.isLoading = false;
        if (data['code'] == 200) {
          this.data = data['data'];
        } else {
          this.commonFunctions.presentAlertConfirm('Information', data['message']);
        }
      },
      error: (error: any) => {
        this.isLoading = false;
        this.commonFunctions.presentAlertConfirm('Information', error);
      }
    });
  }

  getData(query): any[] {
    var filteredData = [];
    if (!query) {
      filteredData = this.data;
    }

    filteredData = this.data.filter(obj => {
      if (!query) {
        return obj;
      }
      return (obj.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 || obj.pincode.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    });
    return filteredData;
  }

  selectLocation(item) {
    window.localStorage.setItem(Ecommerce_Config.session_key + 'location', JSON.stringify(item));
    this.modalController.dismiss({
      'success': true,
    });
  }
}
