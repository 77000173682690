import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Ecommerce_Config } from './Ecommerce_Config';

interface Response {
  error: boolean;
  message: string;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})

export class RestApiService {
  constructor(private http: HttpClient) { }

  // Http Options
  httpOptions() {
    return {
      headers: new HttpHeaders({
        'Authorization': 'jwt ' + window.localStorage.getItem(Ecommerce_Config.session_key + 'token')
      })
    }
  }

  // HttpClient API get() method => Fetch org details
  getOrgDetails(): Observable<Response> {
    return this.http.get<Response>(Ecommerce_Config.api_url + 'module=general&action=get_organization&client=&org=&unique_id=')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API get() method => Fetch warehouse details
  getWarehouseDetails(): Observable<Response> {
    return this.http.get<Response>(Ecommerce_Config.api_url + 'module=general&action=get_warehouse&client=&org=&unique_id=')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API get() method => Fetch product list
  getCMS(): Observable<Response> {
    return this.http.get<Response>(Ecommerce_Config.api_url + 'module=general&action=get_cms&client_id=&org_id=')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API get() method => Fetch product list
  getProductByID(id): Observable<Response> {
    return this.http.get<Response>(Ecommerce_Config.api_url + 'module=product&action=get_by_id&pid=' + id + '&client=&org=')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API get() method => Fetch saved address
  getSavedAddress(id): Observable<Response> {
    return this.http.get<Response>(Ecommerce_Config.api_url + 'module=user&action=get_saved_address&cbid=' + id + '&client=&org=')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API get() method => Fetch all orders
  getAllOrders(id): Observable<Response> {
    return this.http.get<Response>(Ecommerce_Config.api_url + 'module=user&action=get_all_orders&uid=' + id + '&page=1&client_id=&org_id=')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API get() method => Fetch order
  getOrder(uid, oid): Observable<Response> {
    return this.http.get<Response>(Ecommerce_Config.api_url + 'module=user&action=get_order&unique_id=' + '&user_id=' + uid + '&c_order_id=' + oid)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API post() method => Login
  loginUser(data: any): Observable<Response> {
    let formData = new FormData();
    formData.append('name', data.email);
    formData.append('password', data.password);
    formData.append('client_id', '1');
    formData.append('org_id', '1');
    return this.http.post<Response>(Ecommerce_Config.api_url + 'module=user&action=login', formData)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API post() method => Forgotpassword
  forgotPassword(data: any): Observable<Response> {
    let formData = new FormData();
    formData.append('email', data);
    formData.append('client_id', '1');
    formData.append('org_id', '1');
    return this.http.post<Response>(Ecommerce_Config.api_url + 'module=user&action=forgot_password', formData)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API post() method => Validate auth link
  validateAuthLink(data: any): Observable<Response> {
    let formData = new FormData();
    formData.append('token', data);
    formData.append('client_id', '1');
    formData.append('org_id', '1');
    return this.http.post<Response>(Ecommerce_Config.api_url + 'module=user&action=validate_auth_link', formData)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API post() method => Validate auth link
  resetPassword(token: string, data: any): Observable<Response> {
    let formData = new FormData();
    formData.append('token', token);
    formData.append('password', data);
    formData.append('client_id', '1');
    formData.append('org_id', '1');
    return this.http.post<Response>(Ecommerce_Config.api_url + 'module=user&action=reset_password', formData)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API post() method => Login
  changePassword(data: any): Observable<Response> {
    let formData = new FormData();
    formData.append('user_id', window.localStorage.getItem('ad_user_id'));
    formData.append('password', data.new_password);
    formData.append('current_password', data.current_password);
    formData.append('client_id', '1');
    formData.append('org_id', '1');
    return this.http.post<Response>(Ecommerce_Config.api_url + 'module=user&action=change_password', formData)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API post() method => Add Address
  manageAddress(data: any): Observable<Response> {
    let formData = new FormData();
    formData.append('name', data.name);
    formData.append('phone', data.phone);
    formData.append('c_bpartner_location_id', data.c_bpartner_location_id);
    formData.append('address1', data.address1);
    formData.append('address2', data.address2);
    formData.append('city', data.city);
    formData.append('postal', data.postal);
    formData.append('c_country_id', "283");
    formData.append('loctype', "O");
    formData.append('latitude', data.latitude);
    formData.append('longitude', data.longitude);
    formData.append('state', data.state);
    formData.append('c_location_id', data.c_location_id);
    formData.append('ad_user_id', window.localStorage.getItem('ad_user_id'));
    formData.append('c_bpartner_id', window.localStorage.getItem('c_bpartner_id'));
    formData.append('client_id', '1');
    formData.append('org_id', '1');
    return this.http.post<Response>(Ecommerce_Config.api_url + 'module=user&action=add_address', formData)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API post() method => Get invoice
  getInvoice(id): Observable<Response> {
    let formData = new FormData();
    formData.append('CustomerItemCode', 'Y');
    formData.append('ShowStamp', 'N');
    formData.append('LetterHead', 'N');
    formData.append('Value', 'C:\\\\empowerERP\\\\');
    formData.append('AD_User_ID', window.localStorage.getItem('ad_user_id'));
    formData.append('C_Invoice_ID', id);
    formData.append('AD_Client_ID', '1');
    formData.append('AD_Org_ID', '1');
    return this.http.post<Response>(Ecommerce_Config.api_url + 'module=report&action=invoice&type=invoice', formData)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API post() method => Delete Address
  deleteAddress(data: any): Observable<Response> {
    let formData = new FormData();
    formData.append('name', data.name);
    formData.append('phone', data.phone);
    formData.append('c_bpartner_location_id', data.c_bpartner_location_id);
    formData.append('address1', data.address1);
    formData.append('address2', data.address2);
    formData.append('city', data.city);
    formData.append('postal', data.postal);
    formData.append('c_country_id', "283");
    formData.append('loctype', "O");
    formData.append('latitude', data.latitude);
    formData.append('longitude', data.longitude);
    formData.append('state', data.state);
    formData.append('c_location_id', data.c_location_id);
    formData.append('ad_user_id', window.localStorage.getItem('ad_user_id'));
    formData.append('c_bpartner_id', window.localStorage.getItem('c_bpartner_id'));
    formData.append('client_id', '1');
    formData.append('org_id', '1');
    return this.http.post<Response>(Ecommerce_Config.api_url + 'module=user&action=delete_address', formData)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API post() method => Add Address
  placeOrder(data: any): Observable<Response> {
    let formData = new FormData();
    formData.append('delivery_fee', data.delivery_fee);
    formData.append('delivery_type', data.delivery_type);
    formData.append('payment_type', data.payment_type);
    formData.append('cart_items', window.localStorage.getItem('cart'));
    formData.append('c_bpartner_location_id', data.c_bpartner_location_id);
    formData.append('c_location_id', data.c_location_id);
    formData.append('user_id', window.localStorage.getItem('ad_user_id'));
    formData.append('unique_id', '1');
    formData.append('client_id', '1');
    formData.append('org_id', '1');
    formData.append('c_bpartner_id', window.localStorage.getItem('c_bpartner_id'));
    formData.append('email', window.localStorage.getItem('email'));
    return this.http.post<Response>(Ecommerce_Config.api_url + 'module=user&action=place_order', formData)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API post() method => Register
  addUser(data: any): Observable<Response> {
    let formData = new FormData();
    formData.append('fname', data.fname);
    formData.append('lname', data.lname);
    formData.append('password', data.password);
    formData.append('email', data.email);
    formData.append('mobile', data.mobile);
    formData.append('country', '283');
    formData.append('client_id', '1');
    formData.append('org_id', '1');
    return this.http.post<Response>(Ecommerce_Config.api_url + 'module=user&action=add_user', formData)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API post() method => Update user
  updateUser(data: any): Observable<Response> {
    let formData = new FormData();
    formData.append('fname', data.fname);
    formData.append('lname', data.lname);
    formData.append('mobile', data.mobile);
    formData.append('country', '283');
    formData.append('user_id', window.localStorage.getItem('ad_user_id'));
    formData.append('client_id', '1');
    formData.append('org_id', '1');
    return this.http.post<Response>(Ecommerce_Config.api_url + 'module=user&action=update_profile', formData)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API put() method => GET
  get(path): Observable<Response> {
    return this.http.get<Response>(Ecommerce_Config.api_url + path, { headers: this.httpOptions().headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API put() method => POST
  post(path, data): Observable<Response> {
    return this.http.post<Response>(Ecommerce_Config.api_url + path, data, { headers: this.httpOptions().headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API get() method => Fetch order
  verifyUser(token): Observable<Response> {
    return this.http.get<Response>(Ecommerce_Config.api_url + 'module=user&action=validate_user_email&token=' + token)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
