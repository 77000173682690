import { Injectable } from '@angular/core';
import { LoadingController, AlertController } from '@ionic/angular';
import { Ecommerce_Config } from './Ecommerce_Config';

@Injectable({
  providedIn: 'root'
})
export class CommonFunctionsService {

  constructor(public alertController: AlertController, public loadingController: LoadingController) { }

  async presentLoading(msg) {
    const loading = await this.loadingController.create({
      message: msg,
    });
    await loading.present();
  }

  dismissLoading() {
    this.loadingController.dismiss();
  }

  async presentAlertConfirm(ttl, msg) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: ttl,
      message: msg,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Okay');
          }
        }
      ]
    });
    await alert.present();
  }

  getImageBasePath() {
    return Ecommerce_Config.image_base;
  }

  getActualPrice(item) {
    var price = null;
    (item.price).forEach(element => {
      if (element.code === item.selected_qty) {
        if (element.discount_amount > 0) {
          price = element.amount;
        } else {
          price = null;
        }
        return false;
      }
    });
    return price;
  }

  getFinalPrice(item) {
    var price = null;
    (item.price).forEach(element => {
      if (element.code === item.selected_qty) {
        if (element.discount_amount > 0) {
          if (element.discount_type === 'fixed') {
            price = element.amount - element.discount_amount;
          }
          if (element.discount_type === 'percentage') {
            price = element.amount - ((element.amount * element.discount_amount) / 100);
          }
        } else {
          price = element.amount;
        }
        return false;
      }
    });
    return price;
  }

  existInCart(item) {
    var cart = JSON.parse(window.localStorage.getItem(Ecommerce_Config.session_key + 'cart'));
    var exist = false;
    if (cart && (cart.items).length > 0) {
      (cart.items).forEach(element => {
        if (element.product_code == item.code && element.qty_code == item.selected_qty) {
          exist = true;
          return false;
        }
      });
    }
    return exist;
  }

  async addToCart(item) {
    var cart = JSON.parse(window.localStorage.getItem(Ecommerce_Config.session_key + 'cart'));
    if (cart == null) {
      cart = {};
      cart.items = [{ product_code: item.code, qty_code: item.selected_qty, count: 1 }];
    } else {
      (cart.items).push({ product_code: item.code, qty_code: item.selected_qty, count: 1 });
    }
    window.localStorage.setItem(Ecommerce_Config.session_key + 'cart', JSON.stringify(cart));
  }

  increaseToCart(item) {
    var cart = JSON.parse(window.localStorage.getItem(Ecommerce_Config.session_key + 'cart'));
    if (cart != null) {
      (cart.items).forEach(element => {
        if (element.product_code == item.code && element.qty_code == item.selected_qty) {
          element.count = element.count + 1;
          return false;
        }
      });
    }
    window.localStorage.setItem(Ecommerce_Config.session_key + 'cart', JSON.stringify(cart));
  }

  decreaseToCart(item) {
    var cart = JSON.parse(window.localStorage.getItem(Ecommerce_Config.session_key + 'cart'));
    if (cart != null) {
      (cart.items).forEach((element, ind) => {
        if (element.product_code == item.code && element.qty_code == item.selected_qty) {
          element.count = element.count - 1;
          if (element.count == 0) {
            (cart.items).splice(ind, 1);
          }
          return false;
        }
      });
    }
    window.localStorage.setItem(Ecommerce_Config.session_key + 'cart', JSON.stringify(cart));
  }

  getCountInCart(item) {
    var cart = JSON.parse(window.localStorage.getItem(Ecommerce_Config.session_key + 'cart'));
    var count = 0;
    if (cart != null) {
      (cart.items).forEach(element => {
        if (element.product_code == item.code && element.qty_code == item.selected_qty) {
          count = element.count;
          return false;
        }
      });
    }
    return count;
  }

  getCartCount() {
    var cart = JSON.parse(window.localStorage.getItem(Ecommerce_Config.session_key + 'cart'));
    var count = 0;
    if (cart != null && cart.items && (cart.items).length > 0) {
      count = (cart.items).length;
    }
    return count;
  }
}
