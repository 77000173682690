import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { RestApiService } from '../rest-api.service';
import { Router } from '@angular/router';
import { CatpopoverPage } from '../catpopover/catpopover.page';
import { ProfilepopoverPage } from '../profilepopover/profilepopover.page';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { HomelocationPage } from '../homelocation/homelocation.page';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  location: any = null;
  data: any = null;
  category: any = null;
  query: string = '';
  isloading: boolean = false;
  label: string = '';
  constructor(public modalController: ModalController, public restApi: RestApiService, private route: Router, public popover: PopoverController) { }

  ngOnInit() {
    this.location = JSON.parse(window.localStorage.getItem(Ecommerce_Config.session_key + 'location'));
    if (!this.location) {
      this.openLocation();
    }
  }

  ionViewWillEnter() {
    
  }

  getProfileName() {
    if (window.localStorage.getItem('ad_user_id') && window.localStorage.getItem('ad_user_id') != null && (window.localStorage.getItem('ad_user_id')).toLowerCase() != 'null') {
      return 'Hi, ' + window.localStorage.getItem('name');
    } else {
      return 'My Profile';
    }
  }

  goToHome() {
    this.route.navigate(['/home']);
  }

  async createPopover(ev: any, i: number) {
    const pop = await this.popover.create({
      component: CatpopoverPage,
      event: ev,
      translucent: false,
      cssClass: 'fullscreen',
      componentProps: {
        "category": this.category.category
      }
    });
    return await pop.present();
  }

  async profilePopover(ev: any, i: number) {
    const pop = await this.popover.create({
      component: ProfilepopoverPage,
      event: ev,
      translucent: false,
      cssClass: ''
    });
    return await pop.present();
  }

  getCartCount() {
    let cart = JSON.parse(window.localStorage.getItem('cart'));
    if (cart) {
      return cart.length;
    } else {
      return 0;
    }
  }

  goToProducts(cid) {
    let qry = null;
    if (this.query != '') {
      qry = this.query;
      this.route.navigate(['/product/' + cid + '/' + qry]);
    }
  }

  goToSearch() {
    let qry = null;
    if (this.query != '') {
      qry = this.query;
      this.route.navigate(['/search/' + qry]);
    }
  }

  goToProfile() {
    let user_id = window.localStorage.getItem('ad_user_id');
    if (user_id != null && user_id != '') {
      this.route.navigate(['/myprofile']);
    } else {
      this.route.navigate(['/login']);
    }
  }

  goToPage(page) {
    this.route.navigate(['/' + page]);
  }

  getOrgDetails() {
    this.restApi.getOrgDetails().subscribe((data: any) => {
      if (data['error'] == false) {
        this.data = data['data'];
        this.category = data['category'];
      }
    })
  }

  limitChars(str, lmt) {
    if (str.length > lmt) {
      return str.substr(0, (lmt - 3)) + '...';
    } else {
      return str;
    }
  }

  async openLocation() {
    const modal = await this.modalController.create({
      component: HomelocationPage,
      cssClass: '',
      backdropDismiss: false,
    });
    modal.onDidDismiss().then(data => {
      if (data.data && data.data.success && data.data.success == true) {
        this.location = JSON.parse(window.localStorage.getItem(Ecommerce_Config.session_key + 'location'));
        window.location.reload();
      }
    });
    return await modal.present();
  }

}
